import logo from "./logo.png"

const clientData = {
    client_entity: 75,
    attorney_firm: 'Henry, Barbour, DeCell & Bridgforth',
    attorney_name: 'Jay Barbour',
    attorney_number: '(662)746-2134',
    attorney_email: 'jbarbour@hbdblaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_jay_barbour+(1080p).mp4',
    mainColor: '#973504',
    secondaryColor: '#973504',
    siteLink: 'http://www.hbdblaw.com/',
    logo
}

export default clientData